import React from "react";

const LogoIcon: React.FC<{
	size?: number;
}> = props => {
	const size = props.size || 64;
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 58.87 45.16"
			height={size}
			width={size}
			fill="currentColor"
		>
			<path
				id="logo-beanie"
				d="M2.3,25.1c0.3-1.9-1.2-3-1.8-4.6C-2.6,7.5,12.2-2.3,24,0.4c4.9,0.4,9.3,3,13.5,5.4c2.8,1,5.8,1.8,7.4,4.6
	c1.6,2.3,3.7,4.4,3.9,7.3c-0.7,2.2-4.9,2.3-6.9,3.5c-7.8,2.1-15.9,2.9-23.7,5c-4.4,0.7-8.1,3.6-11.7,6C3,33.4,2.4,27.2,2.3,25.1z
	 M30.6,22.6c-0.8-2.7-9.8-12.8-12.2-9.8c1.6,1.5,2.6,3.7,3.6,5.7C25,26.1,22.3,23.7,30.6,22.6z M35,21.7c-2.5-4.6-6.9-8.1-11.3-10.9
	c-0.9-0.5-1.9,0.6-2.8,0.8c2.9,1.5,5.4,3.9,7.7,6.4c1.3,1.3,2.2,2.8,3,4.4C31.5,22.4,35,21.6,35,21.7z M14.8,14.3
	c1.5,2.2,2.6,4.6,3.6,7.1c0.6,3.2,1.6,3.8,4.7,2.6c-1.3-3.9-2.5-7.9-5.5-10.8C16.8,12.8,15.7,14.1,14.8,14.3z M47.6,18
	c-0.9-5.4-6.1-11.6-12.1-10.8c-0.7,0.1,0.5,0.5,0.7,0.7c4,2.8,7.2,6.6,9.5,10.9C45.7,18.7,47.7,17.9,47.6,18z M10.9,16.6
	c1.8,3,3.7,5.8,4.8,9.1c0,0,3-0.7,3-0.7c-1-3.7-2.5-7.1-4.6-10.4C14.1,14.7,10.9,16.6,10.9,16.6z M14.9,25.8c-1.5-3-2.9-6-4.6-8.8
	c0,0-2.4,1.7-2.4,1.7c1.1,2.9,1.8,6.4,4.4,8.4C12.2,27.1,15,25.9,14.9,25.8z M5.9,6.4c1.6,3,3.3,5.9,4.6,9.1l2.8-1.7
	C11.8,11.3,9,1.9,5.9,6.4z M5.3,7c-3.2,3-0.3,8,1.8,10.9c0,0,2.5-1.8,2.5-1.8C8.5,12.9,6.9,10,5.3,7z M7.2,19.2
	c-0.7,0.7-2.4,1.2-2,2.4c0.6,2.8,1.3,5.8,3.1,8c2.4-1.3,4-1.7,1.6-4.1C8.6,23.6,7.9,21.4,7.2,19.2z M38,21
	C36.5,17.8,28,8.7,24.5,10.1c1.5,1.6,4.9,3.5,6.8,5.6c1.8,1.6,3.3,3.7,4.6,5.7C35.9,21.4,38,21,38,21z M40.9,20.3
	c-2.7-4.9-7.5-9.1-12.5-11.6c-0.3,0-1.6,0.3-1.3,0.6c2.3,1.3,4.4,2.9,6.2,4.8c2.1,1.9,4,3.9,5.3,6.4C39.2,21.1,40.2,20.4,40.9,20.3z
	 M9.7,3.4L8.5,4.2c1.5,2.3,3.3,4.4,4.3,7c1.3,3.3,1,2.1,3.9,1C15.1,8.8,12.6,5.8,9.7,3.4z M42.7,19.8C42.6,16.4,32,5.4,30.3,8.5
	c4.5,2.9,8.9,6.7,11.3,11.6C41.7,20.1,42.7,19.8,42.7,19.8z M22.5,9.7C21,5,5.7-3,16.5,5.8c1.4,1.5,2.7,3.1,3.5,4.9
	C20,10.7,22.5,9.7,22.5,9.7z M25.9,8.5c4.2-0.3,0.7-3-0.9-4.7c-1.7-1.8-3.8-3.2-6.3-2.5C21.5,3.3,24,5.7,25.9,8.5z M7.5,30
	c-1.7-2.6-2.4-5.5-3.1-8.5c-2.2,1.6-0.8,3.9-0.6,6.1C4.1,30.4,4.7,33.1,7.5,30z M23.3,9.4c0,0,1.9-0.6,1.9-0.6
	C23,6.2,18.2-0.4,15,1.7C18.1,3.8,21.3,6.1,23.3,9.4z M19.3,11c-0.8-2.1-6.8-9.1-8.8-8c3.1,2.3,5.1,5.6,7.1,8.8
	C17.5,11.8,19.4,11,19.3,11z M44.9,19c-1.8-3.6-7.7-11.6-12-11.4c4.2,3.1,8.8,6.6,10.5,11.7C43.4,19.7,44.9,19,44.9,19z M4.7,19.8
	l2-1.6c-1.6-1.5-2.5-3.5-3.1-5.6C2.1,5.2,2,18.3,4.7,19.8z M30.9,7c-1.3-1.9-2.9-3.5-4.7-4.8C25,1.4,22.2,1,24.8,2.7
	c1.6,1.3,2.9,2.9,4,4.6C29.3,7.9,30.2,7.1,30.9,7z M4,20.3c-1.3-2-1.9-4.1-2.3-6.4c-0.6,2.7-1.1,5.9,1,8.1C2.7,22,4,20.3,4,20.3z
	 M33.2,6.5c-1-2.5-2.6-3.4-5.1-4c1.4,1.3,2.6,2.8,3.7,4.4C31.7,6.8,33.2,6.5,33.2,6.5z M35.8,6.2c-0.6-0.7-1.9-1.2-2.9-1.8
	c0,0,1,2,1,2S35.8,6.2,35.8,6.2z"
			/>
			<path
				id="logo-glasses"
				d="M41.9,40.2c-1.1,0.4-1.2,1.7-1.7,2.6c-0.1,0.9-2,3.5-2.3,1.6c0.4-2.1,1.6-3.9,3.2-5.3c0.4-0.2,0.5-0.5,0.4-0.9
	c-0.2-1.3-0.2-2.6-0.4-3.9c-0.9-0.8-3-0.6-4.1-0.1c-1,3.5-2.2,9.4-7.1,10c-2.9,0.4-6.7-1-7.9-3.9c-1.2-2-0.5-4.8-1.9-6.5
	c-2-1.3-6.1,2.2-8.4,2.9c-0.8,0.6-2.2,0.7-2.6,1.6c-0.9,1.3-0.7,4.5-2.6,4.6c-1.6-0.8,0.8-5.5,2-6.1c5.6-2.7,11-7.5,17.5-7.3
	c3.5,0.3,8-0.2,10.3,3c1.7-0.9,3.5-1.1,5.4-0.2c2-2.8,5.4-4,8.7-3.9c2.3,0,9.1,0.2,8.3,3.5c-0.1,0.5-0.6,0.5-1,0.6
	C57.4,33,57,33.4,57,34c0.2,4.7-2.3,9.8-7.6,9.8C46.6,44,42.9,43.3,41.9,40.2z M26.8,30.8c-4.8-0.6-4.8,3.6-4,7.1
	c0.1,2.9,2.3,4.7,5.1,4.8c4.8,1.3,7.7-4.3,6.9-8.4C33.7,31.3,29.5,31.1,26.8,30.8z M55.8,32.4c-3.9,2.4-9.6,4.1-12.4,7.4
	c1,2.5,4.1,3.4,6.6,3.1C55,42.4,56.7,36.6,55.8,32.4z M55,30.9c-5.1-2.6-13.7-0.4-11.8,6.8C47.1,35.4,50.9,32.9,55,30.9z"
			/>
		</svg>
	);
};

export default LogoIcon;
